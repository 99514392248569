.logo {
  display: flex;
  align-items: center;
}

.cube {
  position: relative;
  width: 30px;
  height: 30px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(45deg);
  margin-right: 10px;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-accent);
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.face:nth-child(1) {
  transform: translateZ(15px);
}

.face:nth-child(2) {
  transform: rotateY(90deg) translateZ(15px);
  background-color: var(--color-accent-light);
}

.face:nth-child(3) {
  transform: rotateX(90deg) translateZ(15px);
  background-color: var(--color-accent-dark);
}

.text {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: var(--color-text-light);
}

.accent {
  color: var(--color-accent);
} 