.footer {
  background-color: var(--color-primary);
  color: var(--color-text-light);
  padding: 3rem 0 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.info {
  flex: 1;
  min-width: 250px;
  margin-bottom: 2rem;
}

.info h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-accent);
}

.info p {
  line-height: 1.5;
  opacity: 0.8;
}

.links {
  display: flex;
  flex-wrap: wrap;
  flex: 2;
  justify-content: space-around;
}

.linkGroup {
  min-width: 150px;
  margin-bottom: 2rem;
}

.linkGroup h4 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--color-accent);
}

.linkGroup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.linkGroup li {
  margin-bottom: 0.5rem;
}

.linkGroup a {
  color: var(--color-text-light);
  text-decoration: none;
  transition: color 0.3s ease;
  opacity: 0.8;
}

.linkGroup a:hover {
  color: var(--color-accent);
  opacity: 1;
}

.bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 0 1rem;
  }
  
  .container {
    padding: 0 1rem;
  }
  
  .top {
    flex-direction: column;
  }
  
  .info {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .links {
    flex-direction: column;
    align-items: center;
  }
  
  .linkGroup {
    text-align: center;
    margin-bottom: 1.5rem;
  }
} 