.section {
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
}

.light {
  background-color: var(--color-background-light);
  color: var(--color-text-dark);
}

.dark {
  background-color: var(--color-background-dark);
  color: var(--color-text-light);
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-text-light);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: var(--color-accent);
  border-radius: 2px;
}

.subtitle {
  font-size: 1.2rem;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.content {
  width: 100%;
}

.centered .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .section {
    padding: 3rem 0;
  }
  
  .container {
    padding: 0 1rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
} 