.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-primary);
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
}

.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav li {
  margin-left: 2rem;
}

.nav a {
  color: var(--color-text-light);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.nav a:hover {
  color: var(--color-accent);
}

.nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--color-accent);
  transition: width 0.3s ease;
}

.nav a:hover::after {
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 1rem;
  }
  
  .nav {
    margin-top: 1rem;
    width: 100%;
  }
  
  .nav ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .nav li {
    margin: 0.5rem;
  }
} 