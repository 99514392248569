.events {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.eventImage {
  position: relative;
  margin-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 8px;
  height: 200px;
}

.eventImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card:hover .eventImage img {
  transform: scale(1.05);
}

.eventDate {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-accent);
  color: white;
  padding: 0.5rem 1rem;
  border-top-right-radius: 8px;
  font-weight: bold;
}

.eventTitle {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.eventLocation {
  font-size: 0.9rem;
  color: var(--color-text-muted);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.eventLocation::before {
  content: '📍';
  margin-right: 0.5rem;
}

.eventDescription {
  margin-bottom: 1.5rem;
  flex-grow: 1;
  color: var(--color-text-muted);
}

.eventLink {
  display: inline-block;
  font-weight: 500;
  margin-top: auto;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .eventImage {
    height: 180px;
  }
} 