.geometricElements {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
}

.background {
  opacity: 0.1;
}

.accent {
  opacity: 0.3;
}

.top {
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.left {
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
}

.right {
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
}

.element {
  position: absolute;
  background-color: var(--element-color, var(--color-accent));
  opacity: 0.5;
  transform: rotate(45deg);
}

.background .element:nth-child(1) {
  width: 100px;
  height: 100px;
  top: -50px;
  left: 10%;
}

.background .element:nth-child(2) {
  width: 50px;
  height: 50px;
  top: 60px;
  left: 30%;
}

.background .element:nth-child(3) {
  width: 75px;
  height: 75px;
  top: 100px;
  right: 25%;
}

.background .element:nth-child(4) {
  width: 120px;
  height: 120px;
  top: -30px;
  right: 10%;
}

.background .element:nth-child(5) {
  width: 60px;
  height: 60px;
  top: 170px;
  left: 50%;
} 