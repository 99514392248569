.puzzleSites {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.siteImage {
  margin-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 8px;
  height: 180px;
}

.siteImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card:hover .siteImage img {
  transform: scale(1.05);
}

.siteName {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
}

.siteDescription {
  margin-bottom: 1.5rem;
  flex-grow: 1;
  color: var(--color-text-muted);
}

.siteLink {
  display: inline-block;
  font-weight: 500;
  margin-top: auto;
  padding-top: 0.5rem;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .siteImage {
    height: 150px;
  }
} 