:root {
  /* カラーパレット */
  --color-primary: #212121;
  --color-primary-light: #484848;
  --color-primary-dark: #000000;
  
  --color-accent: #f50057;
  --color-accent-light: #ff5983;
  --color-accent-dark: #bb002f;
  --color-accent-rgb: 245, 0, 87;
  
  --color-background-light: #ffffff;
  --color-background-dark: #121212;
  
  --color-text-light: #ffffff;
  --color-text-dark: #212121;
  --color-text-muted: #757575;
  
  --color-success: #4caf50;
  --color-warning: #ff9800;
  --color-error: #f44336;
  --color-info: #2196f3;
  
  /* タイポグラフィ */
  --font-family-base: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  --font-family-heading: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  
  /* スペーシング */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;
  
  /* ブレイクポイント */
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
} 