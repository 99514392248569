:root {
  --primary-color: #000000;
  --primary-hover: #333333;
  --accent-color: #008BD2;
  --accent-hover: #0074B3;
  --text-primary: #000000;
  --text-secondary: #666666;
  --background-color: #FFFFFF;
  --background-alt: #F9F9F9;
  --card-width: 345px;
  --card-padding: 16px;
}

body {
  background-color: var(--background-color);
  color: var(--text-primary);
  font-family: 'Futura', sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px 0;
}

.header-content {
  max-width: 1200px;
  margin: 0;
  padding: 0 24px;
}

.header-logo {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}

.header-logo-link {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  position: relative;
}

.header-logo-image {
  height: 40px;
  width: auto;
  transition: transform 0.3s ease;
}

.header-title {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-primary);
  font-weight: 500;
}

.header-title-text {
  position: relative;
  display: inline-block;
}

.header-title-text::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-logo-link:hover .header-title-text::after {
  transform: scaleX(1);
  transform-origin: left;
}

.header-logo-link:hover .header-logo-image {
  transform: scale(1.05);
}

.hero {
  background-color: #000000;
  color: white;
  padding: 40px 0;
  position: relative;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(0, 139, 210, 0.1), transparent);
  pointer-events: none;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 2rem;
  margin: 0 0 12px 0;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hero-description {
  font-size: 1.125rem;
  margin: 0;
  opacity: 0.9;
  line-height: 1.5;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1200px;
  margin: 64px auto;
  padding: 0 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--card-width), 1fr));
  gap: 32px;
  justify-items: center;
}

.card {
  width: var(--card-width);
  background-color: var(--background-color);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  outline: none;
}

.card:focus-visible {
  box-shadow: 0 0 0 3px var(--accent-color);
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.card:active {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.card-media {
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-color: var(--background-color);
  position: relative;
}

.card-media img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 16px;
  transition: transform 0.3s ease-in-out;
}

.card-content {
  padding: var(--card-padding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  width: 100%;
}

.card-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-title {
  margin: 0 0 8px 0;
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.card:hover .card-title {
  color: var(--accent-color);
}

.card-description {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-secondary);
  line-height: 1.5;
  flex-grow: 1;
}

.card-actions {
  padding: 12px var(--card-padding);
  display: flex;
  justify-content: flex-start;
  background-color: var(--background-color);
}

.card-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  padding: 8px 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: block;
  pointer-events: none;
}

.card-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.card:hover .card-button::after {
  transform: translateX(0);
}

.card-button span {
  position: relative;
  z-index: 2;
}

@media (hover: none) {
  .card {
    transform: none !important;
  }
  
  .card:active {
    background-color: var(--background-alt);
  }
  
  .card-button {
    background-color: var(--accent-color);
  }
}

.footer {
  margin-top: 40px;
  padding: 24px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-content {
  text-align: center;
}

.footer-content p {
  margin: 0;
  font-size: 0.8rem;
  color: var(--text-secondary);
  letter-spacing: 0.5px;
}

.announcements {
  background-color: var(--background-alt);
  padding: 48px 0;
}

.announcements-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.announcements-title {
  font-size: 1.75rem;
  margin: 0 0 24px 0;
  color: var(--text-primary);
  font-weight: 500;
}

.announcements-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.announcement-item {
  display: flex;
  gap: 16px;
  align-items: baseline;
  padding: 16px;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.announcement-date {
  font-size: 0.875rem;
  color: var(--text-secondary);
  white-space: nowrap;
}

.announcement-text {
  margin: 0;
  font-size: 1rem;
  color: var(--text-primary);
}

.announcement-link {
  color: var(--text-primary);
  text-decoration: none;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.announcement-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--accent-color);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.announcement-link:hover {
  color: var(--accent-color);
}

.announcement-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.section-title {
  font-size: 1.75rem;
  margin: 0 0 32px 0;
  color: var(--text-primary);
  font-weight: 500;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .announcement-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .card-media {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
  }

  .card-media img {
    padding: 8px;
  }

  .card-content {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  .card-title {
    font-size: 1.1rem;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-description {
    font-size: 0.8rem;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-actions {
    padding: 0 12px;
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .card-button {
    padding: 6px 24px;
    font-size: 0.9rem;
    width: 100%;
    min-width: auto;
  }
}
