.tools {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

.card {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 1.5rem;
}

.card:hover {
  transform: translateY(-5px);
}

.toolIcon {
  flex: 0 0 60px;
  height: 60px;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolIcon img {
  max-width: 100%;
  max-height: 100%;
}

.toolContent {
  flex: 1;
}

.toolName {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.toolDescription {
  font-size: 0.9rem;
  color: var(--color-text-muted);
  margin: 0;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  .card {
    padding: 1rem;
  }
  
  .toolIcon {
    flex: 0 0 50px;
    height: 50px;
    margin-right: 1rem;
  }
} 